.menuImg {
  max-width: 100%;
  cursor: pointer;
}

.menuText {
  font-weight: 600;
  color: gray;
  margin-top: 0.3rem;
}

/* Media query for small screens (e.g., mobile phones) */
@media screen and (max-width: 1195px) {
  .sidebar_tab_fontSize {
    font-size: 12px;
  }
  .sidebar_padding {
    padding: 1rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

/* Media query for large screens (e.g., desktops) */
@media screen and (min-width: 1200px) {
  .sidebar_tab_fontSize {
    font-size: 16px;
  }

  .sidebar_padding {
    padding: 1.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
